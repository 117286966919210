/*
 * @Author: Jc
 * @Date: 2021-12-27 16:52:28
 * @LastEditors: Jc
 * @LastEditTime: 2022-04-24 16:11:08
 * @FilePath: /mall-front-static/src/api/credit/index.ts
 * @Description:
 *
 */
import request, { baseQueryByPage } from '@/utils/request';
import { IRequestResult } from '../type/tool';
import { ICreditInfo, ICreditItem } from '@/api/type/base';

// 查询积分
export function getPointInfo(pointId: number): Promise<IRequestResult<ICreditInfo>> {
  return request.get(`/user/point/customerInfo/${pointId}`, { hideMsg: true });
}

// 查询积分记录
export const getCreditList = baseQueryByPage<
  {
    // 积分类型
    pointId: number;
    // 模糊查询
    title?: string;
    // 变动渠道
    channel?: string;
    // 渠道唯一标识
    channelCode?: string;
    // 状态列表
    statusList?: string[];
    // 开始时间
    startDate?: string;
    // 结束时间
    endDate?: string;
  },
  ICreditItem
>('/user/point/record/list');

// 查询积分
export function getStatistics(param: {
  // 积分类型
  pointId: number;
  // 开始时间
  startDate: string;
  // 结束时间
  endDate: string;
}): Promise<
  IRequestResult<{
    /* 已使用 */
    useCount: number;
    /* 已获取 */
    gainCount: number;
  }>
> {
  /* current size 两个参数无用 后台统一逻辑校验必填 */
  return request.post(`/user/point/statistics`, { ...param, current: 1, size: 10 }, { hideMsg: true });
}
