





























































import { Component } from 'vue-property-decorator';
import ConfirmOrder from '@/views/create-order/confirm-order.vue';
import { getPointInfo } from '@/api/credit';
import { MerchantModule } from '@/store/modules/Merchant';
import { calcMoney, calcMoneyCredit, formatMoney } from '@/filters/calcMoney';
import calcCredit from '@/filters/calcCredit';
import { PayTypeEnum } from '@/api/type/base';
import { calcDeductPriceAndCredit, MathAdd, MathSubtract } from '@/utils/math';
import router from '@/router';
import { isEmpty } from 'lodash';
@Component({
  name: '',
  extends: ConfirmOrder
})
export default class extends ConfirmOrder {
  /* 是否使用积分折扣 */
  isDiscount = false;

  usableCount = 0;

  /* 实际支付 */
  get actualPayment() {
    const credit = this.isDiscount ? calcMoneyCredit(this.discountPrice) : 0;
    const coupon = isEmpty(this.couponInfo) ? 0 : calcMoney(this.couponInfo.consumePrice);
    const discount = MathAdd(credit, coupon);
    return formatMoney(MathSubtract(this.totalPrice, discount), false);
  }

  get calcMoney() {
    return calcMoney;
  }

  get calcMoneyCredit() {
    return calcMoneyCredit;
  }

  get calcCredit() {
    return calcCredit;
  }

  onSelectCredit() {
    if (!this.usableCount) return;
    this.isDiscount = !this.isDiscount;
  }

  // 获取积分
  async getPointInfo() {
    const { payload } = await getPointInfo(MerchantModule.payPointId);
    this.usableCount = payload.usableCount;
  }

  /* 最大折扣金额 (分) */
  get discountPrice() {
    const max = this.skuList.reduce((total, item) => {
      if (item.productSpu.inputRules) {
        const deduct = calcDeductPriceAndCredit(item.currentPrice, item.productSpu);
        total += +deduct.credit || 0;
      }
      return total;
    }, 0);

    if (max > this.usableCount && this.usableCount) {
      return this.usableCount;
    }

    return max;
  }

  beforePay() {
    this.payType = this.isDiscount ? PayTypeEnum.POINT_AND_WECHAT : PayTypeEnum.PAY_TYPE_WECHAT;
  }

  /* 实际支付金额 */
  afterPay() {
    router.replace({
      name: 'buyRes',
      query: {
        payType: this.payType,
        price: this.actualPayment
      }
    });
  }

  mounted() {
    this.getPointInfo();
  }
}
